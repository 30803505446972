import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { theme } from '../../../helpers/theme'
import { space } from 'styled-system'
import Link from 'gatsby-link'

const Trademark = styled.section({}, space)

Trademark.subSection = styled.div({}, space)

Trademark.Header = styled(Typography).attrs(() => ({ variant: 'h1' }))({}, space)

Trademark.Text = styled(Typography).attrs(() => ({ variant: 'body1', component: 'span' }))``

Trademark.inlineLink = styled(Link)`
  color: ${theme.colors.sitebuilderBlue};
  text-decoration: none;
  font-size: inherit;
  font-style: inherit;
  &:hover {
    cursor: pointer;
    color: ${theme.colors.sitebuilderBlue};
  }
`

export default Trademark
